<template>
  <b-row>
    <b-col>
      {{ this.getOfficeData(this.item, this.type) }}
    </b-col>
  </b-row>
</template>
<script>

export default {
  props: ['item', 'type'],
  data () {
    return {
    }
  },
  async created () {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    getOfficeData (item, type) {
      if (parseInt(item.not_here_designation) === 0 && parseInt(item.profession_type) === 1) {
        let desigObj
        if (type === 'admin') {
          desigObj = this.$store.state.commonObj.designationList.find(designation => designation.value === item.designation_id)
        }
        if (type === 'panel') {
          desigObj = this.$store.state.ExternalUserIrrigation.commonObj.designationList.find(designation => designation.value === item.designation_id)
        }
        if (typeof desigObj !== 'undefined') {
          return this.$i18n.locale === 'en' ? desigObj.text_en : desigObj.text_bn
        } else {
          return ''
        }
      } else {
        return this.$i18n.locale === 'en' ? item.designation_en : item.designation_bn
      }
    }
  }
}
</script>
